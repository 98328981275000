/* Invoice Lookup, Vue Component */
<template>
    <v-autocomplete
        :value="value"
        @input="onChange"
        :items="items"
        :loading="isLoading"
        :search-input.sync="search"
        :solo-inverted="inverted"
        :solo="!inverted"
        :outlined="outlined"
        :dense="dense"
        :hide-details="hideDetails"
        hide-no-data
        clearable
        flat
        item-value="Id"
        :label="label"
        :placeholder="placeholder"
        :readOnly="readOnly"
        :disabled="disabled"
        :error-messages="errorMessages"
        class="lookup"
        :auto-select-first="true"
        :rules="rules"
        :filter="Filter"
    >
    <template v-slot:selection="data">
        {{ data.item.Reference }}
    </template>
    <template v-slot:item="data">
        <v-list-item-content class="py-1">
            <v-list-item-title v-html="data.item.Reference"></v-list-item-title>
            
        </v-list-item-content>
    </template>
    </v-autocomplete>
</template>

<script>
import { requiredLookup } from '@/datahelpers/validation'

export default {
    props: {
        value: {
            required: true
        },
        label: {
            type: String
        },
        placeholder: {
            type: String,
            default: "Search Invoice"
        },
        disabled: {
            type: Boolean,
            default: false
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        "errorMessages": {
            type: Array,
            default: function() {
                return [];
            }
        },
        rules: {
            type: Array,
            default: function() {
                return [];
            }
        },
        dense: {
            type: Boolean,
            default: false
        },
        inverted: {
            type: Boolean,
            default: true
        },
        outlined: {
            type: Boolean,
            default: false
        },
        hideDetails: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            isLoading: true,
            search: null,
            items: [],
            validators: {
                requiredLookup
            }
        };
    },
    computed: {
    },
    watch: {
        search(value) {
            value && value !== this.select && this.loadData(value);
        },
        value(value) {
            value && value !== this.select && this.loadItem(value);
        }
    },
    methods: {
        onChange(item) {
            this.$emit("input", item);
        },
        Filter(item, queryText, itemText) {
            //return item.name.toLocaleLowerCase().includes(queryText.toLocaleLowerCase());
            
            return item.Reference.toLocaleLowerCase().includes(queryText.toLocaleLowerCase());
            
        },
        loadItem(Id) {
            this.isLoading = true;

            this.$store.dispatch('app/fetchInvoicesLookup', {
                id: Id
            })
            .then(data => {
                this.isLoading = false;
                this.items = [data];
            })
            .catch(error => {
                console.log(error)
            });
        },
        loadData(value) {
            this.isLoading = true;

            this.$store.dispatch('app/fetchInvoicesLookup', {
                search: {
                    all: value
                }
            })
            .then(data => {
                this.isLoading = false;
                this.items = data;
            })
            .catch(error => {
                console.log(error)
            });
        }
    },
    beforeMount: function() {
        this.loadData("");
    }
}
</script>

<style lang="scss" scoped>
.v-select-list::v-deep .v-list-item {
  height: auto;
}
</style>
